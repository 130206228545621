<template>
<div id="fever">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>

  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1294px; margin:0 auto; min-height:calc(100vh - 160px - 272px);">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">オンラインで診療</div>
          </div>
            
            <div  class="content1">
              <div class="side-text">
                <div class="question_title">ご希望される診療内容</div>
                <el-select v-model="treatmentInfo.treatmentType" placeholder="【選択してください】" @change="OnSelectContent()">
                  <el-option label="一般内科" value="一般内科"></el-option>
                  <el-option label="発熱外来" value="発熱外来"></el-option>
                  <el-option label="コロナ予防接種" value="コロナ予防接種"></el-option>
                  <el-option label="メディカルダイエット" value="メディカルダイエット"></el-option>
                  <el-option label="プラセンタ" value="プラセンタ"></el-option>
                  <el-option label="エクソソーム点滴" value="エクソソーム点滴"></el-option>
                </el-select>               
              </div>

              <div class="side-text" id="not_diet" style="visibility:hidden; height: 0px; overflow: hidden; ">
                <div class="content1">
                  <div class="question_title">受診される方の情報をご登録ください</div>
                </div>

                <div class="content1">
                  <div class="question_title">氏名
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td><el-input v-model="name_1" placeholder="姓"></el-input></td>
                        <td><el-input v-model="name_2" placeholder="名"></el-input></td>
                      </tr>
                      <tr>
                        <td><el-input v-model="kana_1" placeholder="セイ"></el-input></td>
                        <td><el-input v-model="kana_2" placeholder="メイ"></el-input></td>
                      </tr>
                    </table>  
                    <div style="margin-top: 10px;">
                    ＊カタカナで入力できない場合は、英語でご入力ください  
                    </div>                                                                    
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">生年月日
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-select placeholder="" filterable v-model="yearSelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="year,index in yearRange" :label="year" :value="year" :key="index"></el-option>
                    </el-select>　年　

                    <el-select placeholder="" style="width: 70px;" filterable v-model="monthSelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="month,index in monthRange" :label="month" :value="month" :key="index"></el-option>
                    </el-select>　月　
                    
                    <el-select placeholder="" style="width: 70px;" filterable v-model="daySelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="day,index in dayRange" :label="day" :value="day" :key="index"></el-option>
                    </el-select>　日　
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">性別
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.gender==='男性'?'primary':''" @click="OnSelectGender('男性')" style="width: 100%;">男性</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.gender==='女性'?'primary':''" @click="OnSelectGender('女性')" style="width: 100%;">女性</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">クリニックからの連絡方法
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.hopeContact==='電話'?'primary':''" @click="OnSelectHopeContact('電話')" style="width: 100%;">電話</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.hopeContact==='メール'?'primary':''" @click="OnSelectHopeContact('メール')" style="width: 100%;">メール</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>                

                <div class="content1" id="mail" style="height: 0px;overflow: hidden;margin-top: 0px;">
                  <div class="question_title">メールアドレス
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.email"></el-input>
                  </div>
                  <div style="margin-top: 10px;">
                    ＊半角英数字でご入力ください。<br>
                    ＊当サイトからのメールは「＠hope-medical.jp」で送信致します。<br>
                    「＠hope-medical.jp」からのメールを受信できるように設定をお願いします。<br>
                  </div>
                  <div style="margin-top: 10px;">
                    <el-checkbox v-model="haveMailAddress" label="メールアドレスを持っていない"> </el-checkbox>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">電話番号
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.tel"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">郵便番号
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.postcode"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">住所
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.address"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">当院の受診は初めてですか
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.isFirstTreatment==='はい'?'primary':''" @click="OnSelectFFirstTreatment('はい')" style="width: 100%;">はい</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.isFirstTreatment==='いいえ'?'primary':''" @click="OnSelectFFirstTreatment('いいえ')" style="width: 100%;">いいえ</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">病症や伝えたい内容をご記入ください
                  </div>
                  <div class="question_content">
                    <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8 }" resize="none" placeholder="" v-model="treatmentInfo.treatmentContent"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">診療希望日（一つ以上の診療希望日をご記入ください）
                  <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <div>
                      第1希望日：
                      <el-date-picker style="width: 50%;" v-model="hopeDate1" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>                      
                    </div>
                    <div style="margin-top: 20px;">
                      第1希望日の時間帯：
                      <el-radio-group v-model="treatmentInfo.firstHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio>
                        <el-radio label="午後">午後</el-radio>
                        <el-radio label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>

                    <div style="margin-top: 40px;">
                      第2希望日：
                      <el-date-picker style="width: 50%;"  v-model="hopeDate2" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>
                    </div>
                    <div style="margin-top: 20px;">
                      第2希望日の時間帯：
                      <el-radio-group v-model="treatmentInfo.secondHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio>
                        <el-radio label="午後">午後</el-radio>
                        <el-radio label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>

                    <div style="margin-top: 40px;">
                      第3希望日：
                      <el-date-picker style="width: 50%;"  v-model="hopeDate3" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>
                    </div>
                    <div style="margin-top: 20px;">
                      第3希望日の時間帯：
                      <el-radio-group v-model="treatmentInfo.thirdHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio>
                        <el-radio label="午後">午後</el-radio>
                        <el-radio label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
               
                <table class="name_table" style="margin-top: 30px;">
                      <tr>
                        <td style="width: 50%;"><el-button  @click="onCancel()" style="width: 70%; margin-left: 15%;">キャンセル</el-button></td>
                        <td style="width: 50%;"><el-button  @click="onConfirm(true)" style="width: 70%; margin-left: 15%;" >確認画面へ</el-button></td>
                      </tr>
                </table>
              </div>

             
                
                
              <div class="side-text" id="diet" style="visibility:hidden; height: 0px; overflow: hidden;">
                <div class="text1" style="color: #ff0000; font-weight: bold;">＊ダイエットの予約はこちらへ</div>
                <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
                  <div class="side-box">
                    <img src="../../assets/SNS/line.png" style="height:50px">
                    <div class="side-text-line">
                      <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                      <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <el-dialog
              title=""
              :visible.sync="submitDialogVisible"
              width="45%"
              style="z-index: 50;">
              <div style="width: 100%; text-align: center; font-weight: bold;margin-bottom: 30px;">入力内容をご確認ください</div>
              <table style="width: 90%; margin-left: 5%" class="dialog_table">
                <tr>
                  <td style="width: 40%;">氏名：</td>
                  <td style="width: 60%;">{{treatmentInfo.name}}</td>
                </tr>
                <tr>
                  <td>フリガナ：</td>
                  <td>{{treatmentInfo.nameKana}}</td>
                </tr>
                <tr>
                  <td>生年月日：</td>
                  <td>{{treatmentInfo.birthday}}</td>
                </tr>
                <tr>
                  <td>性別：</td>
                  <td>{{treatmentInfo.gender}}</td>
                </tr>
                <tr>
                  <td>メールアドレス：</td>
                  <td>{{treatmentInfo.email}}</td>
                </tr>
                <tr>
                  <td>電話番号:</td>
                  <td>{{treatmentInfo.tel}}</td>
                </tr>
                <tr>
                  <td>ご希望される診療内容：</td>
                  <td>{{treatmentInfo.treatmentType}}</td>
                </tr>
                <tr>
                  <td>第１診療希望日：</td>
                  <td>{{treatmentInfo.firstHopeDate}}　{{treatmentInfo.firstHopeTime}}</td>
                </tr>
                <tr>
                  <td>第２診療希望日：</td>
                  <td>{{treatmentInfo.secondHopeDate}}　{{treatmentInfo.secondHopeTime}}</td>
                </tr>
                <tr>
                  <td>第３診療希望日：</td>
                  <td>{{treatmentInfo.thirdHopeDate}}　{{treatmentInfo.thirdHopeTime}}</td>
                </tr>
                <tr>
                  <td>クリニックからの連絡方法：</td>
                  <td>{{treatmentInfo.hopeContact}}</td>
                </tr>
              </table>
              <div style="margin-top: 30px; width: 90%; margin-left: 5%; font-size: 15px;">
                <div style="color:#ff0000">重要：</div>
                <div>
                  ご予約を送信した後、変更したい場合は、直接クリニックに電話するか、もう一回「Web予約」から同じ流れで申し込んでください。<br>
                </div>
                <div>
                  キャンセルしたい場合は、直接クリニックに電話するか、もう一回Web予約から【病症や伝えたい内容をご記入ください】のところに「何日の予約をキャンセルしたい」をご記入の上、同じ流れで申し込んでください。
                </div>
              </div>


              <div slot="footer" class="dialog-footer" style=" text-align: center;">
                <el-button @click="submitDialogVisible = false;submitDialogVisible_m = false" style="margin-right: 7%;">キャンセル</el-button>
                <el-button type="primary" @click="onSubmit()" style="margin-left: 7%;">送信する</el-button>
              </div>
            </el-dialog>

            
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1294px; margin:0 auto">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">オンラインで診療</div>
          </div>
          <div  class="content1">
              <div class="side-text">
                <div class="question_title">ご希望される診療内容</div>
                <el-select v-model="treatmentInfo.treatmentType" placeholder="【選択してください】" @change="OnSelectContent()">
                  <el-option label="一般内科" value="一般内科"></el-option>
                  <el-option label="発熱外来" value="発熱外来"></el-option>
                  <el-option label="コロナ予防接種" value="コロナ予防接種"></el-option>
                  <el-option label="メディカルダイエット" value="メディカルダイエット"></el-option>
                  <el-option label="プラセンタ" value="プラセンタ"></el-option>
                  <el-option label="エクソソーム点滴" value="エクソソーム点滴"></el-option>
                </el-select>               
              </div>

              <div class="side-text" id="not_diet_m" style="visibility:hidden; height: 0px; overflow: hidden; ">
                <div class="content1">
                  <div class="question_title">受診される方の情報をご登録ください</div>
                </div>

                <div class="content1">
                  <div class="question_title">氏名
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td><el-input v-model="name_1" placeholder="姓"></el-input></td>
                        <td><el-input v-model="name_2" placeholder="名"></el-input></td>
                      </tr>
                      <tr>
                        <td><el-input v-model="kana_1" placeholder="セイ"></el-input></td>
                        <td><el-input v-model="kana_2" placeholder="メイ"></el-input></td>
                      </tr>
                    </table>  
                    <div style="margin-top: 10px;">
                    ＊カタカナで入力できない場合は、英語でご入力ください  
                    </div>                                                                    
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">生年月日
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-select placeholder="" style="width: 207px;" v-model="yearSelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="year,index in yearRange" :label="year" :value="year" :key="index"></el-option>
                    </el-select>　年

                    <br><el-select placeholder="" style="width: 70px;margin-top: 20px" v-model="monthSelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="month,index in monthRange" :label="month" :value="month" :key="index"></el-option>
                    </el-select>　月
                    
                    <el-select placeholder="" style="width: 70px;margin-left: 30px" v-model="daySelect" popper-class="birthday_option" :popper-append-to-body="true">
                      <el-option v-for="day,index in dayRange" :label="day" :value="day" :key="index"></el-option>
                    </el-select>　日
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">性別
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.gender==='男性'?'primary':''" @click="OnSelectGender('男性')" style="width: 100%;">男性</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.gender==='女性'?'primary':''" @click="OnSelectGender('女性')" style="width: 100%;">女性</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">クリニックからの連絡方法
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.hopeContact==='電話'?'primary':''" @click="OnSelectHopeContact('電話')" style="width: 100%;">電話</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.hopeContact==='メール'?'primary':''" @click="OnSelectHopeContact('メール')" style="width: 100%;">メール</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>                

                <div class="content1" id="mail_m" style="height: 0px;overflow: hidden;margin-top: 0px;">
                  <div class="question_title">メールアドレス
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.email"></el-input>
                  </div>
                  <div style="margin-top: 10px;">
                    ＊半角英数字でご入力ください。<br>
                    ＊当サイトからのメールは「＠hope-medical.jp」で送信致します。<br>
                    「＠hope-medical.jp」からのメールを受信できるように設定をお願いします。<br>
                  </div>
                  <div style="margin-top: 10px;">
                    <el-checkbox v-model="haveMailAddress" label="メールアドレスを持っていない"> </el-checkbox>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">電話番号
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.tel"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">郵便番号
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.postcode"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">住所
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <el-input v-model="treatmentInfo.address"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">当院の受診は初めてですか
                    <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <table class="name_table">
                      <tr>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.isFirstTreatment==='はい'?'primary':''" @click="OnSelectFFirstTreatment('はい')" style="width: 100%;">はい</el-button></td>
                        <td style="width: 50%;"><el-button :type="treatmentInfo.isFirstTreatment==='いいえ'?'primary':''" @click="OnSelectFFirstTreatment('いいえ')" style="width: 100%;">いいえ</el-button></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">病症や伝えたい内容をご記入ください
                  </div>
                  <div class="question_content">
                    <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8 }" resize="none" placeholder="" v-model="treatmentInfo.treatmentContent"></el-input>
                  </div>
                </div>

                <div class="content1">
                  <div class="question_title">診療希望日
                  <div class="must_mark">必要</div>
                  </div>
                  <div class="question_content">
                    <div>
                      第1希望日：
                      <el-date-picker style="width: 100%; margin-top: 10px;" v-model="hopeDate1" :editable="false" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>                      
                    </div>
                    <div style="margin-top: 10px;">
                      第1希望日の時間帯：<br>
                      <el-radio-group style="margin-top: 10px;" v-model="treatmentInfo.firstHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="午後">午後</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>

                    <div style="margin-top: 40px;">
                      第2希望日：
                      <el-date-picker style="width: 100%; margin-top: 10px;"  v-model="hopeDate2" :editable="false" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>
                    </div>
                    <div style="margin-top: 10px;">
                      第2希望日の時間帯：<br>
                      <el-radio-group style="margin-top: 10px;" v-model="treatmentInfo.secondHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="午後">午後</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>

                    <div style="margin-top: 40px;">
                      第3希望日：
                      <el-date-picker style="width: 100%; margin-top: 10px;"  v-model="hopeDate3" :editable="false" :clearable="true" :picker-options="{disabledDate}"></el-date-picker>
                    </div>
                    <div style="margin-top: 10px;">
                      第3希望日の時間帯：<br>
                      <el-radio-group style="margin-top: 10px;" v-model="treatmentInfo.thirdHopeTime">
                        <el-radio label="午前" type="primary">午前</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="午後">午後</el-radio><br>
                        <el-radio style="margin-top: 5px;" label="どちらでも可">どちらでも可</el-radio>
                      </el-radio-group>
                    </div>
                    <div style="margin-top: 10px;">＊一つ以上の診療希望日をご記入ください</div>
                  </div>
                </div>
               
                <table class="name_table" style="margin-top: 30px;">
                      <tr>
                        <td style="width: 50%;text-align: center"><el-button  @click="onCancel()" style="">キャンセル</el-button></td>
                        <td style="width: 50%;text-align: center"><el-button  @click="onConfirm(false)" style="" >確認画面へ</el-button></td>
                      </tr>
                </table>
              </div>

             
                
                
              <div class="side-text" id="diet_m" style="visibility:hidden; height: 0px; overflow: hidden;">
                <div class="text1" style="color: #ff0000; font-weight: bold;">＊ダイエットの予約はこちらへ</div>
                <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
                  <div class="side-box">
                    <img src="../../assets/SNS/line.png" style="height:50px">
                    <div class="side-text-line">
                      <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                      <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                    </div>
                  </div>
                </div>
              </div>

          </div>

          <el-dialog
            title=""
            :visible.sync="submitDialogVisible_m"
            width="85%"
            append-to-body>
            <div style="width: 100%; text-align: center; font-weight: bold;margin-bottom: 30px;">入力内容をご確認ください</div>
            <table style="width: 90%; margin-left: 5%" class="dialog_table_m">
              <tr>
                <td style="width: 40%;">氏名：</td>
                <td style="width: 60%;">{{treatmentInfo.name}}</td>
              </tr>
              <tr>
                <td>フリガナ：</td>
                <td>{{treatmentInfo.nameKana}}</td>
              </tr>
              <tr>
                <td>生年月日：</td>
                <td>{{treatmentInfo.birthday}}</td>
              </tr>
              <tr>
                <td>性別：</td>
                <td>{{treatmentInfo.gender}}</td>
              </tr>
              <tr>
                <td>メールアドレス：</td>
                <td>{{treatmentInfo.email}}</td>
              </tr>
              <tr>
                <td>電話番号:</td>
                <td>{{treatmentInfo.tel}}</td>
              </tr>
              <tr>
                <td>ご希望される診療内容：</td>
                <td>{{treatmentInfo.treatmentType}}</td>
              </tr>
              <tr>
                <td>第１診療<br>希望日：</td>
                <td>{{treatmentInfo.firstHopeDate}}<br>{{treatmentInfo.firstHopeTime}}</td>
              </tr>
              <tr>
                <td>第２診療<br>希望日：</td>
                <td>{{treatmentInfo.secondHopeDate}}<br>{{treatmentInfo.secondHopeTime}}</td>
              </tr>
              <tr>
                <td>第３診療<br>希望日：</td>
                <td>{{treatmentInfo.thirdHopeDate}}　{{treatmentInfo.thirdHopeTime}}</td>
              </tr>
              <tr>
                <td>クリニック<br>からの<br>連絡方法：</td>
                <td>{{treatmentInfo.hopeContact}}</td>
              </tr>
            </table>
            <div style="margin-top: 30px; width: 90%; margin-left: 5%; font-size: 14px;">
              <div style="color:#ff0000">重要：</div>
              <div>
                ご予約を送信した後、変更したい場合は、直接クリニックに電話するか、もう一回「Web予約」から同じ流れで申し込んでください。<br>
              </div>
              <div>
                キャンセルしたい場合は、直接クリニックに電話するか、もう一回Web予約から【病症や伝えたい内容をご記入ください】のところに「何日の予約をキャンセルしたい」をご記入の上、同じ流れで申し込んでください。
              </div>
            </div>


            <div slot="footer" class="dialog-footer" style=" text-align: center;">
              <el-button @click="submitDialogVisible = false;submitDialogVisible_m = false" style="margin-right: 7%;">キャンセル</el-button>
              <el-button type="primary" @click="onSubmit()" style="margin-left: 7%;">送信する</el-button>
            </div>
          </el-dialog>

        </div>
    <m-departs/>
    <m-footer/>
  </div>
  
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import { Message } from "element-ui";
import { Notify} from "vant";
//import { classof } from "core-js/core/object";
export default {
  name: "Fever",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader},
  data(){
    return{
      PatientManual: './secondFile/患者用操作マニュアル.pdf',
      treatmentInfo:{
        name:'',
        nameKana:'',
        birthday:'',
        gender:'',
        tel:'',
        email:'',
        postcode:'',
        address:'',
        treatmentType:'',
        treatmentContent:'',
        isFirstTreatment:'',
        firstHopeDate:'',
        firstHopeTime:'',
        secondHopeDate:'',
        secondHopeTime:'',
        thirdHopeDate:'',
        thirdHopeTime:'',
        hopeContact:'',
        isChecked:'',
      },
      name_1:'',
      name_2:'',
      kana_1:'',
      kana_2:'',

      yearRange:[],
      yearSelect:'',
      monthRange:[],
      monthSelect:'',
      dayRange:[],
      daySelect:'',

      hopeDate1:'',
      hopeDate2:'',
      hopeDate3:'',

      holidayRange:[],

      haveMailAddress:'',

      submitDialogVisible:false,
      submitDialogVisible_m:false,
    }
  },
  methods:{
    download(path, title) {
      let a = document.createElement('a')
      a.setAttribute('href', path)
      a.setAttribute('download', title)
      document.body.appendChild(a)
      a.click()
    },
    goHref(path) {
      window.location.href = path;
    },
    OnSelectContent(){
      if(this.treatmentInfo.treatmentType != "メディカルダイエット"){
        document.getElementById("not_diet").style.visibility="visible"
        document.getElementById("diet").style.visibility="hidden"

        document.getElementById("not_diet").style.height="auto";
        document.getElementById("diet").style.height="0px"

        document.getElementById("not_diet_m").style.visibility="visible"
        document.getElementById("diet_m").style.visibility="hidden"

        document.getElementById("not_diet_m").style.height="auto";
        document.getElementById("diet_m").style.height="0px"
      }
      else{
        document.getElementById("not_diet").style.visibility="hidden"
        document.getElementById("diet").style.visibility="visible"

        document.getElementById("not_diet").style.height="0px";
        document.getElementById("diet").style.height="auto";

        document.getElementById("not_diet_m").style.visibility="hidden"
        document.getElementById("diet_m").style.visibility="visible"

        document.getElementById("not_diet_m").style.height="0px";
        document.getElementById("diet_m").style.height="auto";
      }
    },

    OnSelectGender(selectGender){
      this.treatmentInfo.gender = selectGender;
    },

    OnSelectHopeContact(contact){
      console.log(contact)
      this.treatmentInfo.hopeContact = contact;
      if(contact ==  "メール"){
        var mail = document.getElementById("mail");
        //mail.style.display="display"
        mail.style.height="187px"
        mail.style.marginTop="40px"

        var mail_m = document.getElementById("mail_m");
        //mail.style.display="display"
        mail_m.style.height="250px"
        mail_m.style.marginTop="40px"
      }
      else{
        var mail = document.getElementById("mail");
        //mail.style.display="none"
        mail.style.height="0px"
        mail.style.marginTop="0px"

        var mail_m = document.getElementById("mail_m");
        //mail.style.display="none"
        mail_m.style.height="0px"
        mail_m.style.marginTop="0px"
      }
    },

    OnSelectFFirstTreatment(option){
      this.treatmentInfo.isFirstTreatment = option;
    },

    disabledDate(date){
      if(date < Date.now()){
        return true;
      }
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var d = `${year}-${month<10?"0":""}${month}-${day<10?"0":""}${day}`;
      if(this.holidayRange.indexOf(d) != -1)
      {
        return true;
      }
      if(date.getDay() == 0 || date.getDay() == 3)
      {
        return true;
      }
      if((date - Date.now())/(1*24*60*60*1000) > 30)
      {
        return true;
      }
      return false;
      
    },

    onCancel(){
      this.$router.go(-1);
    },

    onConfirm(size){
      //表单输入验证,并记录部分数据
      if(true){
        //名字为空
        if(this.name_1.replace(/\s*/g,"") == '' 
        || this.name_2.replace(/\s*/g,"") == '' 
        || this.kana_1.replace(/\s*/g,"") == '' 
        || this.kana_2.replace(/\s*/g,"") == ''){
          Message.error("氏名・フリガナをご記入ください")
          return;
        }
        
        //未选择生年月日ｓ
        if(this.yearSelect == '' || this.monthSelect == '' || this.daySelect == ''){
          Message.error("生年月日をご選択ください")
          //Message.error("生年月日をご選択ください")
          return;
        }
        
        //未选择性别
        if(this.treatmentInfo.gender == ''){
          
          Message.error("性別をご選択ください")
          return;
        }
        //未选择诊所联系方式
        if(this.treatmentInfo.hopeContact == ''){
          Message.error("希望する連絡方法をご選択ください")
          return;
        }
        //(选择邮箱联系）
        if(this.treatmentInfo.hopeContact == 'メール'){
          //未填写邮箱
          if(this.treatmentInfo.email == ''){
            Message.error("メールアドレスをご記入ください")
            return;
          }
          var emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
          //邮箱格式错误
          if(!emailPattern.test(this.treatmentInfo.email)){
            Message.error("メールアドレスが間違っています")
            return;
          }
        }
        
        //未填写电话
        if(this.treatmentInfo.tel == ''){
          Message.error("電話番号をご記入ください")
          return;
        }

        var reg_phone_cn = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        var reg_tel_cn = /^(0d{2,3})-?(d{7,8})$/;
        var reg_phone_jp =/^(?:\+81|0)(?:\d{1,4})(?:-?\d{1,4})(?:-?\d{4})$/;
        var reg_tel_jp = /^(050|070|080|090)-?\d{4}-?\d{4}$/;
        //电话格式填写错误
        if(!reg_phone_cn.test(this.treatmentInfo.tel)
          &&!reg_tel_cn.test(this.treatmentInfo.tel)
          &&!reg_phone_jp.test(this.treatmentInfo.tel)
          &&!reg_tel_jp.test(this.treatmentInfo.tel)){

          Message.error("電話番号が間違っています")
          return;
        }
        //未填写邮政编号
        if(this.treatmentInfo.postcode == ''){
          Message.error("郵便番号をご記入ください")
        }
        
        //未填写住所
        if(this.treatmentInfo.address == ''){
          Message.error("住所をご記入ください")
          return;
        }

        //未选择是否初诊
        if(this.treatmentInfo.isFirstTreatment == ''){
          Message.error("当院の受診は初めてかどうかをご選択ください")
          return;
        }
        //未选择诊疗希望日（其中之一有即可）　 
        if(this.hopeDate1 == ''
          &&this.hopeDate2 == ''
          &&this.hopeDate3 == ''){
          Message.error("診療希望日を一つ以上ご記入ください")
          return;
        }   

        //未选择诊疗时间
        if(this.hopeDate1 != '' && this.treatmentInfo.firstHopeTime == ''){
          Message.error("第１希望日の診療時間帯をご記入ください")
          return;
        }   
        if(this.hopeDate2 != '' && this.treatmentInfo.secondHopeTime == ''){
          Message.error("第２希望日の診療時間帯をご記入ください")
          return;
        }   
        if(this.hopeDate3 != '' && this.treatmentInfo.thirdHopeTime == ''){
          Message.error("第３希望日の診療時間帯をご記入ください")
          return;
        }   
        
      }
      //合并生成部分字段
      {
        this.treatmentInfo.name = `${this.name_1}　${this.name_2}`;
        this.treatmentInfo.nameKana = `${this.kana_1}　${this.kana_2}`;

        var year = this.yearSelect.split('　')[0];
        var nengo = this.yearSelect.split('　')[1];
        this.treatmentInfo.birthday=`${year}年${this.monthSelect}月${this.daySelect}日`

        if(this.hopeDate1 != ''){
          var year = this.hopeDate1.getFullYear();
          var month = this.hopeDate1.getMonth() + 1;
          var day = this.hopeDate1.getDate();
          this.treatmentInfo.firstHopeDate = `${year}年${month<10?"0":""}${month}月${day<10?"0":""}${day}日`;
        }
        else{
          this.treatmentInfo.firstHopeTime = '';
        }

        if(this.hopeDate2 != ''){
          var year = this.hopeDate2.getFullYear();
          var month = this.hopeDate2.getMonth() + 1;
          var day = this.hopeDate2.getDate();
          this.treatmentInfo.secondHopeDate = `${year}年${month<10?"0":""}${month}月${day<10?"0":""}${day}日`;
        }
        else{
          this.treatmentInfo.secondHopeTime = '';
        }

        if(this.hopeDate3 != ''){
          var year = this.hopeDate3.getFullYear();
          var month = this.hopeDate3.getMonth() + 1;
          var day = this.hopeDate3.getDate();
          this.treatmentInfo.thirdHopeDate = `${year}年${month<10?"0":""}${month}月${day<10?"0":""}${day}日`;
        }
        else{
          this.treatmentInfo.thirdHopeTime = '';
        }
      }
      
      //弹出对话框
      if(size){
        this.submitDialogVisible = true;
      }
      else{
        this.submitDialogVisible_m = true
      }      
    },
    onSubmit(){
      axios.post(`${this.GLOBAL.host}/t/contact/insertTreatment`, this.treatmentInfo).then(() => {
        Message.success('送信しました')
              setTimeout(() => {
                this.$router.go(-1)
              }, 3000);
            }).catch(() => {
              Message.warning('キャンセルしました')
          })
    }

},
  created(){
    //获取年
    axios.get(`${this.GLOBAL.host}/t/year/getYear`).then(resp => {
      this.yearRange = [];
      resp.data.map(i=>{
        this.yearRange.push(i.year + "　"+ i.nengo)
      })
      
    })

    let i = 0;
    for(i=1;i<=12;i++){
      this.monthRange.push(i);
    }
    for(i=1;i<=31;i++){
      this.dayRange.push(i);
    }

    //获取假期范围
    axios.get(`${this.GLOBAL.host}/t/holiday/getHolidays`).then(resp=>{
      resp.data.map(i=>{
        var date = new Date(i.holidayDate)
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var d = `${year}-${month<10?"0":""}${month}-${day<10?"0":""}${day}`;
        this.holidayRange.push(d)
      })
    })

    console.log(this.holidayRange)
    
  }
}
</script>

<style>


.birthday_option .el-select-dropdown__wrap{
  max-height: 500px !important;
}
</style>

<style scoped>
.dialog_table{
  border-collapse: collapse;
}
.dialog_table td{
  height: 60px;
  border-bottom: #aaaaaa 1px solid;
}

.dialog_table_m{
  border-collapse: collapse;
}
.dialog_table_m td{
  height: 50px;
  border-bottom: #aaaaaa 1px solid;
}

.question_title{
  width: 100%;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #535353;
  padding-right: 5px;
  position: relative;
}

.question_content{
  margin-top: 5px;
}

.must_mark{
  font-size: 15px;
  color: #ffffff;
  background-color: #f45757;
  width: 45px;
  text-align: center;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 2px;
  line-height: 30px;
  border-radius: 8px;
}

.name_table{
  width: 100%;
  border-width: 0px;
}

/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 40px;
  transition: 1s;
}

.content_text{
    margin-top: 18px;
}
.title1 {
  color: #ff0000;
  font-size: 18px;
  margin-bottom: 10px;
}

.text1 {
  color: #535353;
  line-height: 1.5;
  margin-bottom: 5px;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #e55e5e;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }
    

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e5d05e;
  border-bottom: 2px solid #e5d05e;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #f2e289;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

/*診療時間*/
.table table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}
.table table tr:first-child {
  background-color: #f0f0f0;
}
.table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 16px;
}

.table_m table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.table_m table tr:first-child {
  background-color: #f0f0f0;
}
.table_m table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

    .separator {
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }

    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }
    
    
    .side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
  
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

.side-line-highlight{
  background-color: #e5d05e;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.link{
  background-color: #62b6e2;
  border: 1px solid #62b6e2;
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  transition: 150ms;
}
.m_link{
  background-color: #62b6e2;
  border: 1px solid #62b6e2;
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  border-radius: 8px;
  margin-top: 20px;
  height: 50px;
  cursor: pointer;
  transition: 50ms;
}

.link:hover{
  background-color: #ffffff;
  color: #62b6e2;
}

.m_link:active{
  background-color: #ffffff;
  color: #62b6e2;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  cursor: pointer;
  transition: 150ms;
  margin-bottom: 20px;
}

.LINE_btn:hover{
  background-color: #ffffff;
  color: #20ce88;
  box-shadow: 5px 5px 10px #42859b66,
  3px 3px 0px #20ce881b;
}



</style>
